<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="pricing_loading"
  >
    <b-loading
      v-if="pricing_loading && !created"
      :is-full-page="false"
      :active="true"
    />
    <div
      v-if="created"
      slot="actions"
    >
      <div class="field has-addons">
        <div class="is-flex mr-2">
          <b-button
            v-if="$device.mobile"
            size="is-small"
            rounded
            @click="showMobile()"
          >
            <b-icon
              icon="magnify"
              size="is-small"
            />
          </b-button>
          <b-input
            ref="search"
            v-model="columns[0].input"
            :placeholder="placeholders.number['3']"
            icon="magnify"
            rounded
            :size="$device.mobile ? 'is-small' : ''"
            :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
            @input="getPricing()"
            @blur="hideMobile()"
          />
        </div>
        <b-dropdown
          ref="dropdown"
          aria-role="list"
          position="is-bottom-left"
          :close-on-click="false"
          scrollable
          max-height="400px"
          append-to-body
        >
          <div slot="trigger">
            <b-button
              icon-left="dots-vertical"
              type="is-default"
              :size="$device.mobile ? 'is-small' : ''"
              rounded
            />
          </div>

          <b-dropdown-item
            v-if="$can('create', 'catalog.autopricing')"
            aria-role="listitem"
            @click="openForm(-1, {})"
          >
            <b-icon
              icon="plus"
              size="is-small"
            />
            {{ $t("create") }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('update', 'catalog.autopricing')"
            aria-role="listitem"
            @click="confirmExecutingAutopricing(null, '', $t('are_you_sure'))"
          >
            <b-icon
              icon="cog-play"
              size="is-small"
            />
            {{ $t("execute") }}
          </b-dropdown-item>

          <b-dropdown-item separator />

          <b-dropdown-item
            aria-role="listitem"
            class="pr-3"
            @click="showColumnsTogle = !showColumnsTogle"
          >
            <b-icon
              icon="view-column"
              size="is-small"
            />
            {{ $tc("column", 2) }}
            <b-icon
              class="is-pulled-right"
              :icon="!showColumnsTogle ? 'chevron-down' : 'chevron-up'"
              size="is-small"
            />
          </b-dropdown-item>
          <template v-if="showColumnsTogle">
            <template v-for="item in columns">
              <b-dropdown-item
                :key="item.key"
                aria-role="listitem"
                :focusable="false"
                custom
              >
                <b-switch
                  v-model="item.visible"
                  type="is-success"
                >
                  {{ item.label }}
                </b-switch>
              </b-dropdown-item>
            </template>
          </template>
        </b-dropdown>
      </div>
    </div>

    <Card
      v-if="created"
      content-class="c-table"
    >
      <b-table
        :data="items"
        :loading="pricing_loading"
        hoverable
        mobile-cards
        striped
        backend-sorting
        :default-sort="defaultSorting"
        :sort-multiple="sortingMultiple"
        :sort-multiple-data="sortingPriority"
        sort-multiple-key="shiftKey"
        :total="total"
        :row-class="
          (row, index) =>
            row.id == updated_pricing
              ? 'bg-updated'
              : row.id == deleted_pricing
                ? 'bg-deleted'
                : ''
        "
        @sort="sortPressed"
        @sorting-priority-removed="sortingPriorityRemoved"
      >
        <template v-for="head in columns">
          <b-table-column
            :key="head.key"
            :visible="head.visible"
            :field="head.key"
            :label="head.label"
            :width="head.width"
            :centered="head.centered"
            :sortable="head.sortable"
            :searchable="table.filterable && head.filterable"
            v-bind="head"
            header-class="sticky-header"
          >
            <template
              v-if="head.filterable"
              slot="searchable"
            >
              <template v-if="head.type == 'date'">
                <b-datepicker
                  v-model="head.input"
                  rounded
                  :placeholder="placeholders.date.day"
                  size="is-small"
                  editable
                  :range="head.filter.key == 'dr'"
                  :min-date="new Date('01/01/2013')"
                  @input="getPricing()"
                />
                <span
                  class="custom-date-filter"
                  @click="showDrop(head.key)"
                >
                  <b-icon
                    icon="filter"
                    size="is-small"
                  />
                </span>
                <b-dropdown
                  :ref="`filter_drop_${head.key}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <b-dropdown-item
                    v-for="operator in head.operators"
                    :key="operator.label"
                    aria-role="listitem"
                    @click="changeFilter(head.key, operator)"
                  >
                    {{ operator.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <template v-else-if="head.type == 'select'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  size="is-small"
                  :expanded="true"
                  hide-details
                  @change="getPricing()"
                >
                  <template>
                    <option
                      v-for="opt in head.filters"
                      :key="opt.id"
                      :value="opt.id"
                    >
                      {{ opt.label }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else-if="head.type == 'boolean'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  expanded
                  hide-details
                  size="is-small"
                  @change="getPricing()"
                >
                  <template>
                    <option :value="true">
                      {{ $t("yes") }}
                    </option>
                    <option :value="false">
                      {{ $t("no") }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template
                v-else-if="
                  head.type == 'number' ||
                    head.type == 'text' ||
                    head.type == 'custom'
                "
              >
                <b-input
                  v-model="head.input"
                  :placeholder="head.filter.label"
                  rounded
                  hide-details
                  size="is-small"
                  icon-right="filter"
                  icon-right-clickable
                  @icon-right-click="showDrop(head.key)"
                  @input="getPricing"
                />
                <b-dropdown
                  :ref="`filter_drop_${head.key}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <b-dropdown-item
                    v-for="operator in head.operators"
                    :key="operator.label"
                    aria-role="listitem"
                    @click="changeFilter(head.key, operator)"
                  >
                    {{ operator.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </template>

              <template v-else-if="head.type == 'autocomplete'">
                <b-autocomplete
                  v-model="head.input"
                  :placeholder="$t('choose')"
                  rounded
                  hide-details
                  size="is-small"
                  :data="getAutocompleteFilters(head.filters, head.input)"
                  field="label"
                  clearable
                  @select="getPricing()"
                />
              </template>
            </template>

            <!-- id -->
            <template
              v-if="head.key == 'id'"
              v-slot="props"
            >
              <b-icon
                :icon="getStatusIcon(props.row.status)"
                :type="getStatusColor(props.row.status)"
                size="is-small"
                class="mr-2"
                :class="props.row.status ? null : 'is-invisible'"
              />
              <a
                v-if="
                  $can('read', 'catalog.autopricing') ||
                    $can('update', 'catalog.autopricing')
                "
                href="javascript:;"
                @click="openForm(props.index, props.row)"
              >{{ props.row.id }}</a>
              <span v-else>{{ props.row.id }}</span>
            </template>

            <!-- site -->
            <template
              v-else-if="head.key == 'site'"
              v-slot="props"
            >
              <img
                height="20"
                width="20"
                :src="'/img/sites/' + props.row.site.id + '.svg'"
              >
            </template>

            <!-- tld -->
            <template
              v-else-if="head.key == 'tld'"
              v-slot="props"
            >
              <img
                height="18"
                width="18"
                :src="'/img/flags/' + props.row.site.tld + '.svg'"
              >
            </template>

            <!-- discount -->
            <template
              v-else-if="head.key === 'discount'"
              v-slot="props"
            >
              {{ props.row.discount && props.row.discount.formatted }}
            </template>

            <!-- repeat -->
            <template
              v-else-if="head.key === 'repeat'"
              v-slot="props"
            >
              {{ props.row.repeat && props.row.repeat.label }}
            </template>

            <!-- created_at -->
            <template
              v-else-if="head.key === 'created_at'"
              v-slot="props"
            >
              <span>
                {{
                  (props.row.created_at && props.row.created_at.formatted) ||
                    "-"
                }}
                <span class="has-text-grey-light">{{
                  props.row.created_at && props.row.created_at.time
                }}</span>
              </span>
            </template>

            <!-- starts_at -->
            <template
              v-else-if="head.key === 'starts_at'"
              v-slot="props"
            >
              <span>
                {{
                  (props.row.starts_at && props.row.starts_at.formatted) || "-"
                }}
                <span class="has-text-grey-light">{{
                  props.row.starts_at && props.row.starts_at.time
                }}</span>
              </span>
            </template>

            <!-- ends_at -->
            <template
              v-else-if="head.key === 'ends_at'"
              v-slot="props"
            >
              <span>
                {{ (props.row.ends_at && props.row.ends_at.formatted) || "-" }}
                <span class="has-text-grey-light">{{
                  props.row.ends_at && props.row.ends_at.time
                }}</span>
              </span>
            </template>

            <!-- expires_at -->
            <template
              v-else-if="head.key === 'expires_at'"
              v-slot="props"
            >
              <span>
                {{
                  (props.row.expires_at && props.row.expires_at.formatted) ||
                    "-"
                }}
                <span class="has-text-grey-light">{{
                  props.row.expires_at && props.row.expires_at.time
                }}</span>
              </span>
            </template>

            <!-- brands -->
            <template
              v-else-if="head.key == 'brands'"
              v-slot="props"
            >
              <template v-if="props.row.brands && props.row.brands.length">
                <span>{{ props.row.brands[0].label }}</span>
                <b-tag
                  v-if="props.row.brands && props.row.brands.length > 1"
                  slot="trigger"
                  class="ml-2"
                  rounded
                >
                  +{{ props.row.brands.length - 1 }}
                </b-tag>

                <!-- <b-dropdown
                  v-if="props.row.brands.length > 1"
                  aria-role="list"
                  position="is-bottom-left"
                  :triggers="['hover']"
                  class="ml-2"
                >
                  <b-tag
                    slot="trigger"
                    rounded
                  >
                    +{{ props.row.brands.length - 1 }}
                    <b-icon
                      icon="chevron-down"
                      size="is-small"
                      class="is-clickable ml-1"
                    />
                  </b-tag>

                  <template v-for="br in props.row.brands">
                    <b-dropdown-item
                      :key="br.id"
                      target="_blank"
                      replace
                      aria-role="listitem"
                    >
                      {{ br.label }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown> -->
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </template>

            <!-- skus -->
            <template
              v-else-if="head.key == 'skus'"
              v-slot="props"
            >
              <template v-if="props.row.skus && props.row.skus.length">
                <span>{{ props.row.skus[0].label }}</span>
                <b-tag
                  v-if="props.row.skus && props.row.skus.length > 1"
                  slot="trigger"
                  class="ml-2"
                  rounded
                >
                  +{{ props.row.skus.length - 1 }}
                </b-tag>
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </template>

            <!-- actions -->

            <template
              v-else-if="head.key == 'actions'"
              v-slot="props"
            >
              <b-dropdown
                :key="props.row.id"
                aria-role="list"
                position="is-bottom-left"
                append-to-body
              >
                <div slot="trigger">
                  <b-icon
                    icon="dots-vertical"
                    size="is-small"
                    class="is-clickable"
                  />
                </div>

                <b-dropdown-item
                  v-if="props.row.actions.view"
                  aria-role="listitem"
                  @click="openForm(props.index, props.row)"
                >
                  <b-icon
                    icon="eye"
                    size="is-small"
                  />
                  {{ props.row.actions.view.label }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="props.row.actions.duplicate"
                  aria-role="listitem"
                  @click="openForm(-1, props.row)"
                >
                  <b-icon
                    icon="content-duplicate"
                    size="is-small"
                  />
                  {{ props.row.actions.duplicate.label }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="props.row.actions.execute"
                  aria-role="listitem"
                  @click="confirmExecutingAutopricing(props.index, props.row.id, props.row.actions.execute.message)"
                >
                  <b-icon
                    icon="cog-play"
                    size="is-small"
                  />
                  {{ props.row.actions.execute.label }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="props.row.actions.delete"
                  aria-role="listitem"
                  @click="
                    props.row.status == 'ongoing'
                      ? confirmDeleting(
                        props.index,
                        props.row.id,
                        props.row.actions.delete.message
                      )
                      : deletePricingAutomation(props.index, props.row.id)
                  "
                >
                  <b-icon
                    icon="delete"
                    type="is-danger"
                    size="is-small"
                  />
                  {{ props.row.actions.delete.label }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.actions.restore"
                  aria-role="listitem"
                  @click="restorePricingAutomation(props.index, props.row.id)"
                >
                  <b-icon
                    icon="restore"
                    type="is-success"
                    size="is-small"
                  />
                  {{ props.row.actions.restore.label }}
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template
              v-else
              v-slot="props"
            >
              <b-icon
                v-if="typeof props.row[head.key] === 'boolean'"
                :icon="props.row[head.key] ? 'check' : 'close'"
                :type="props.row[head.key] ? 'is-success' : 'is-danger'"
                custom-size="mdi-18px"
              />
              <template v-else>
                {{ props.row[head.key] || "-" }}
              </template>
            </template>
          </b-table-column>
        </template>
        <EmptyBlock
          slot="empty"
          icon="ticket-percent"
        />
      </b-table>
      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="total"
        :current-page="currentPage"
        @update-per-page="(val) => (perPage = val)"
        @update-current-page="(val) => (currentPage = val)"
      />
    </Card>
  </Page>
</template>

<script>
import moment from "moment";
import debounce from "debounce";

export default {
  data() {
    return {
      showColumnsTogle: false,
      searchFocused: false,
      updated_pricing: null,
      deleted_pricing: null,
      columns: [],
      items: [],
      table: {},
      sortingMultiple: false,
      sortingPriority: [],
      singleSorting: {},
      defaultSorting: [],
      currentPage: 0,
      perPage: 0,
      perPages: [],
      pricing_loading: true,
      total: 0,
      created: false,
      sites: [],
      brands: [],
      repeatOptions: [],
    };
  },
  watch: {
    currentPage() {
      if (!this.pricing_loading) this.getPricing();
    },
    perPage() {
      if (!this.pricing_loading) this.getPricing();
    },
  },
  created() {
    this.updateTitle(this.$t("autopricing"));
    if (!this.created) this.getPricing();
  },
  mounted() {
    this.$bus.$on("save-princing-automation", (params) => {
      if (params.index !== -1)
        this.items.splice(params.index, 1, params.pricing_automation);
      else this.items.unshift(params.pricing_automation);
      this.updated_pricing = params.pricing_automation.id;
      setTimeout(() => {
        this.updated_pricing = null;
      }, 2000);
    });
  },
  destroyed() {
    this.$bus.$off("save-princing-automation");
  },
  methods: {
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    closeDrop() {
      this.$refs["dropdown"].toggle();
    },
    showDrop(field) {
      this.$refs[`filter_drop_${field}`][0].toggle();
    },
    initFilters() {
      for (let i = 0; i < this.columns.length; i++) {
        this.columns[i]["filter"] =
          this.columns[i] &&
          this.columns[i].operators &&
          this.columns[i].operators.length
            ? this.columns[i].operators[0]
            : null;
      }
    },
    changeFilter(field, value) {
      let index = this.columns.findIndex((h) => h.key == field);
      this.columns[index].filterable = false;
      this.columns[index].filter = value;
      this.columns[index].filterable = true;
      if (this.columns[index].input) this.getPricing();
    },
    sortPressed(field, order, event) {
      if (event["shiftKey"]) {
        let existingPriority = this.sortingPriority.filter(
          (i) => i.field === field
        )[0];
        if (existingPriority) {
          existingPriority.order =
            existingPriority.order === "desc" ? "asc" : "desc";
        } else {
          if (this.singleSorting.field)
            this.sortingPriority.push(this.singleSorting);
          this.sortingPriority.push({ field, order });
        }
        this.singleSorting = {};
      } else {
        this.sortingPriority = []; // [{field, order}]
        this.singleSorting = { field, order };
      }
      this.getPricing();
    },
    sortingPriorityRemoved(value) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== value
      );
      this.getPricing();
    },
    getAutocompleteFilters(filters, input) {
      return input
        ? filters.filter((option) => {
            return (
              option.label
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            );
          })
        : filters;
    },
    getStatusIcon(status) {
      switch (status) {
        case "ongoing":
          return "play-circle";
        case "onhold":
          return "pause-circle";
        case "expired":
          return "stop-circle";
        case null:
          return "unknown";
        default:
          return "unknown";
      }
    },
    getStatusColor(status) {
      switch (status) {
        case "ongoing":
          return "is-success";
        case "onhold":
          return "is-warning";
        case "expired":
          return "is-danger";
        case null:
          return "unknown";
        default:
          return "unknown";
      }
    },
    getPricing: debounce(function () {
      let api_params = "";
      if (this.currentPage && this.perPage)
        api_params = `?pagination=${this.currentPage}:${this.perPage}`;

      if (this.sortingPriority.length > 1) {
        let sortArr = [];
        for (let i = 0; i < this.sortingPriority.length; i++) {
          const sort = this.sortingPriority[i];
          sortArr.push(`${sort.field}:${sort.order}`);
        }
        api_params += `&sorting=${sortArr.join(",")}`;
      } else if (this.sortingPriority.length == 1) {
        api_params += `&sorting=${this.sortingPriority[0].field}:${this.sortingPriority[0].order}`;
      } else if (this.singleSorting.field) {
        api_params += `&sorting=${this.singleSorting.field}:${this.singleSorting.order}`;
      }

      let searchArr = [];

      if (this.table && this.columns) {
        for (let i = 0; i < this.columns.length; i++) {
          const value = this.columns[i];
          if (
            ["brands"].includes(value.key) &&
            value.input &&
            value.input.length != 0 &&
            value.input.length < 3
          )
            return;
          else if (value.input || typeof value.input == "boolean") {
            let obj = {
              c: value.key,
              v: ["starts_at", "ends_at", "created_at", "expires_at"].includes(
                value.key
              )
                ? moment(value.input).format("YYYY-MM-DD")
                : value.input,
            };
            if (value.filter) obj.o = value.filter.key;
            searchArr.push(obj);
          }
        }
      }

      if (searchArr.length) {
        api_params += this.$root.formatFilter(searchArr);
      }

      this.pricing_loading = true;

      this.$axios
        .get("ecommerce/autopricing" + api_params)
        .then((res) => {
          this.table = res.data.table;
          this.items = res.data.list ? res.data.list : [];
          this.brands = res.data.filters.brands;

          this.sortingPriority = this.table.sorting.priority.map((item) => {
            return { field: item.key, order: item.order };
          });
          this.sortingMultiple = this.table.sorting.multiple;
          this.singleSorting = this.sortingPriority[0];
          this.currentPage = this.table.pagination.current;
          this.perPage = this.table.pagination.limit;
          this.perPages = this.table.pagination.limits;
          this.total = this.table.pagination.total;

          if (!this.created) {
            this.columns = this.table.columns;
            this.defaultSorting = [
              this.sortingPriority[0].field,
              this.sortingPriority[0].order,
            ];
            this.initFilters();
          }

          let sitesIndex = this.columns.findIndex(
            (item) => item.key === "site"
          );
          this.sites = this.columns[sitesIndex].filters || [];

          let repeatIndex = this.columns.findIndex(
            (item) => item.key === "repeat"
          );
          this.repeatOptions = this.columns[repeatIndex].filters || [];

          this.created = true;
        })
        .catch((e) => {
          const code = parseInt(e.response && e.response.status);
          if (code) {
            if (code === 404) {
              this.items = [];
              this.total = 0;
            } else {
              this.clientError(e);
            }
          }
        })
        .finally(() => (this.pricing_loading = false));
    }, 200),
    confirmDeleting(index, pricing_automation_id, message) {
      this.$buefy.dialog.confirm({
        message: `<b>${message}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () =>
          this.deletePricingAutomation(index, pricing_automation_id),
      });
    },
    deletePricingAutomation(index, pricing_automation_id) {
      this.$axios
        .delete("ecommerce/autopricing/" + pricing_automation_id)
        .then((res) => {
          this.items[index] = res.data[0];
          this.deleted_pricing = pricing_automation_id;
          setTimeout(() => {
            this.deleted_pricing = null;
          }, 2000);

          // Delete princing automation, move to latest
          // let pricing_automation_copy = JSON.parse(
          //   JSON.stringify(this.items[index])
          // );
          // pricing_automation_copy.active = false;
          // this.items.splice(index, 1, pricing_automation_copy);
        })
        .catch((e) => this.$root.clientError(e));
    },
    restorePricingAutomation(index, pricing_automation_id) {
      this.$axios
        .patch("ecommerce/autopricing/" + pricing_automation_id, { active: true })
        .then((res) => {
          this.items[index] = res.data[0];
          this.updated_pricing = pricing_automation_id;

          setTimeout(() => {
            this.updated_pricing = null;
          }, 2000);
        })
        .catch((e) => this.$root.clientError(e));
    },
    confirmExecutingAutopricing(index, pricing_automation_id, message) {
      this.$buefy.dialog.confirm({
        message: `<b>${message}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () =>
          this.executeAutopricing(index, pricing_automation_id),
      });
    },
    executeAutopricing(index, pricing_automation_id) {
      let url = pricing_automation_id ? ("/" + pricing_automation_id ): "";
      this.$axios
        .post("/artisan/autopricing" + url)
        .then((res) => {
          this.$root.notify(res.data.message, "is-success");
          if (pricing_automation_id) {
            this.items[index].status = res.data.status;
          } else this.getPricing();
        })
        .catch((e) => this.$root.clientError(e));
    },
    openForm(index, pricing_automation) {
      let pricing_automation_form = { ...pricing_automation };
      if (pricing_automation == {}) {
        pricing_automation_form = {
          ...pricing_automation,
        };
      }
      let options = {
        key: pricing_automation.id + index,
        index: index,
        pricing_automation: pricing_automation_form,
        sites: this.sites,
        repeatOptions: this.repeatOptions,
        action: index == -1 ? "create" : "read",
        from: "ecommerce",
        brands: this.brands,
      };
      if (document.activeElement) {
        document.activeElement.blur();
      }
      this.openDrawer("autopricingForm", options);
    },
  },
};
</script>